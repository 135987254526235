const pcHome = () => import('@/views/pc/home/index.vue'); // 首页+
const beauty = () => import('@/views/pc/news/beauty.vue'); // 新闻页 > 美丽与责任
const brand = () => import('@/views/pc/brand/index.vue'); // 品牌页
const patents = () => import('@/views/pc/patents/index.vue'); // 专利
const baike = () => import('@/views/pc/baike/index.vue'); // 百科
const qbfymfsPage = () => import('@/views/pc/qbfymfsPage/index.vue'); // 启邦丰韵美乳酸
const yggjcmrtPage = () => import('@/views/pc/yggjcmrtPage/index.vue'); // 野葛根精萃美乳贴
const mgggdxdbzwypPage = () => import('@/views/pc/mgggdxdbzwypPage/index.vue'); // 木瓜葛根弹性蛋白植物饮品
const mrjhyPage = () => import('@/views/pc/mrjhyPage/index.vue'); // 美乳精华液-次抛
const xbyhjhyPage = () => import('@/views/pc/xbyhjhyPage/index.vue'); // 康兰朵胸部养护精华油
const honor = () => import('@/views/pc/news/honor.vue'); //  荣誉篇章
const subject = () => import('@/views/pc/news/subject.vue'); //  专研实验室
const newBrand = () => import('@/views/pc/news/brand.vue'); //  品牌动态
const sxywPage = () => import('@/views/pc/sxywPage/index.vue'); // 冻干锁鲜燕窝详情
const ywggdtfwypPage = () => import('@/views/pc/ywggdtfwypPage/index.vue'); // 燕窝葛根多肽风味饮品
const ftgPage = () => import('@/views/pc/ftgPage/index.vue'); // 丰挺膏
const pwPage = () => import('@/views/pc/pwPage/index.vue'); // 喷雾
const nxsy = () => import('@/views/pc/nxsy/index.vue'); // 女性公益事业
export default [
  {
    path: '/pc/home',
    component: pcHome,
    name: 'pcHome'
  },
  {
    path: '/pc/news/beauty',
    component: beauty,
    name: 'beauty'
  },
  {
    path: '/pc/news/honor',
    component: honor,
    name: 'honor'
  },
  {
    path: '/pc/news/subject',
    component: subject,
    name: 'subject'
  },
  {
    path: '/pc/brand',
    component: brand,
    name: 'brand'
  },
  {
    path: '/pc/patents',
    component: patents,
    name: 'patents'
  },
  {
    path: '/pc/baike',
    component: baike,
    name: 'baike'
  },
  {
    path: '/pc/qbfymfsPage',
    component: qbfymfsPage,
    name: 'qbfymfsPage'
  },
  {
    path: '/pc/yggjcmrtPage',
    component: yggjcmrtPage,
    name: 'yggjcmrtPage'
  },
  {
    path: '/pc/mgggdxdbzwypPage',
    component: mgggdxdbzwypPage,
    name: 'mgggdxdbzwypPage'
  },
  {
    path: '/pc/mrjhyPage',
    component: mrjhyPage,
    name: 'mrjhyPage'
  },
  {
    path: '/pc/xbyhjhyPage',
    component: xbyhjhyPage,
    name: 'xbyhjhyPage'
  },
  {
    path: '/pc/sxywPage',
    component: sxywPage,
    name: 'sxywPage'
  },
  {
    path: '/pc/ywggdtfwypPage',
    component: ywggdtfwypPage,
    name: 'ywggdtfwypPage'
  },
  {
    path: '/pc/news/brand',
    component: newBrand,
    name: 'newBrand'
  },
  {
    path: '/pc/ftgPage',
    component: ftgPage,
    name: 'ftgPage'
  },
  {
    path: '/pc/pwPage',
    component: pwPage,
    name: 'pwPage'
  },
  {
    path: '/pc/nxsy',
    component: nxsy,
    name: 'nxsy'
  },
];
