const mobileHome = () => import('@/views/mobile/home/index.vue'); // 首页
const beauty = () => import('@/views/mobile/news/beauty.vue'); // 新闻页 > 美丽与责任
const brand = () => import('@/views/mobile/brand/index.vue'); // 品牌页
const patents = () => import('@/views/mobile/patents/index.vue'); // 专利
const mBaiKe = () => import('@/views/mobile/baike/index.vue'); // 百科
const mqbfymfsPage = () => import('@/views/mobile/qbfymfsPage/index.vue'); // 启邦丰韵美乳酸
const myggjcmrtPage = () => import('@/views/mobile/yggjcmrtPage/index.vue'); // 野葛根精萃美乳贴
const mmgggdxdbzwypPage = () => import('@/views/mobile/mgggdxdbzwypPage/index.vue'); // 木瓜葛根弹性蛋白植物饮品
const mmrjhyPage = () => import('@/views/mobile/mrjhyPage/index.vue'); // 美乳精华液-次抛
const mxbyhjhyPage = () => import('@/views/mobile/xbyhjhyPage/index.vue'); // 康兰朵胸部养护精华油
const honor = () => import('@/views/mobile/news/honor.vue'); // 荣誉篇章
const subject = () => import('@/views/mobile/news/subject.vue'); // 专研实验室
const newBrand = () => import('@/views/mobile/news/brand.vue'); // 品牌动态
const msxywPage = () => import('@/views/mobile/sxywPage/index.vue'); // 冻干锁鲜燕窝详情
const mywggdtfwypPage = () => import('@/views/mobile/ywggdtfwypPage/index.vue'); // 燕窝葛根多肽风味饮品
const mftgPage = () => import('@/views/mobile/ftgPage/index.vue'); // 丰挺膏
const mpwPage = () => import('@/views/mobile/pwPage/index.vue'); // 喷雾
const nxsy = () => import('@/views/mobile/nxsy/index.vue'); //  女性公益事业
export default [
  {
    path: '/mobile/home',
    component: mobileHome,
    name: 'mobileHome'
  },
  {
    path: '/mobile/news/beauty',
    component: beauty,
    name: 'mobileBeauty'
  },
  {
    path: '/mobile/news/honor',
    component: honor,
    name: 'mobilehonor'
  },
  {
    path: '/mobile/news/subject',
    component: subject,
    name: 'mobilesubject'
  },
  {
    path: '/mobile/brand',
    component: brand,
    name: 'mobileBrand'
  },
  {
    path: '/mobile/patents',
    component: patents,
    name: 'mobilePatents'
  },
  {
    path: '/mobile/baike',
    component: mBaiKe,
    name: 'mBaiKe'
  },
  {
    path: '/mobile/qbfymfsPage',
    component: mqbfymfsPage,
    name: 'mqbfymfsPage'
  },
  {
    path: '/mobile/yggjcmrtPage',
    component: myggjcmrtPage,
    name: 'myggjcmrtPage'
  },
  {
    path: '/mobile/mgggdxdbzwypPage',
    component: mmgggdxdbzwypPage,
    name: 'mmrjhmmgggdxdbzwypPageyPage'
  },
  {
    path: '/mobile/mrjhyPage',
    component: mmrjhyPage,
    name: 'mmrjhyPage'
  },
  {
    path: '/mobile/xbyhjhyPage',
    component: mxbyhjhyPage,
    name: 'mxbyhjhyPage'
  },
  {
    path: '/mobile/sxywPage',
    component: msxywPage,
    name: 'msxywPage'
  },
  {
    path: '/mobile/ywggdtfwypPage',
    component: mywggdtfwypPage,
    name: 'mywggdtfwypPage'
  },
  {
    path: '/mobile/news/brand',
    component: newBrand,
    name: 'newBrand'
  },
  {
    path: '/mobile/ftgPage',
    component: mftgPage,
    name: 'mftgPage'
  },
  {
    path: '/mobile/pwPage',
    component: mpwPage,
    name: 'mpwPage'
  },
  {
    path: '/mobile/nxsy',
    component: nxsy,
    name: 'nxsy'
  },
];
